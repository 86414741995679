import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, Credentials } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  private readonly PILEJE_ADMIN_USERNAMES = [
    'pilejeAdmin',
    'pilejeAdmin1',
    'pilejeAdmin2',
    'pilejeAdmin3',
    'pilejeAdmin4',
    'pilejeAdmin5',
    'pilejeAdmin6',
  ];

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      const credentials: Credentials = {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
      };

      this.authService.login(credentials).subscribe(() => {
        if (this.PILEJE_ADMIN_USERNAMES.includes(credentials.username)) {
          this.router.navigate(['pileje/practitioners']);
        } else {
          this.router.navigate(['dashboard']);
        }
      });
    }
  }
}
