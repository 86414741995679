import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';

export interface Credentials {
  username: string;
  password: string;
}

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
  expiresInSeconds: number;
}

@Injectable()
export class AuthService {
  private readonly CURRENT_USER_TOKENS = 'current_user_tokens';
  private readonly CURRENT_USERNAME = 'current_username';

  private authUrl = environment.api.authUrl + '/auth';
  private readonly loginEndpoint: string;
  public readonly refreshTokenEndpoint: string;

  private http: HttpClient;

  private authTokenSubject: BehaviorSubject<AuthTokens>;

  constructor(private handler: HttpBackend, private router: Router) {
    this.loginEndpoint = this.authUrl + '/authorize';
    this.refreshTokenEndpoint = this.authUrl + '/refresh-token';
    this.http = new HttpClient(handler);
    this.authTokenSubject = new BehaviorSubject<AuthTokens>(
      this.getAuthToken()
    );
  }

  public get authTokensValue(): AuthTokens {
    return this.authTokenSubject.value;
  }

  login(credentials: Credentials): Observable<AuthTokens> {
    return this.http
      .post<AuthTokens>(this.loginEndpoint + '/admin', credentials)
      .pipe(
        tap((authTokens) => {
          this.storeAuthToken(authTokens);
          this.storeUsername(credentials.username);
        })
      );
  }

  refreshToken(): Observable<AuthTokens> {
    const token = this.getAuthToken();
    if (!token) {
      return of(null);
    }
    return this.http
      .get<AuthTokens>(this.refreshTokenEndpoint, {
        headers: {
          Authorization: `Bearer ${token.refreshToken}`,
        },
      })
      .pipe(
        tap((authTokens) => {
          this.storeAuthToken(authTokens);
        }),
        catchError((error) => {
          this.logout();
          return throwError(error);
        })
      );
  }

  logout() {
    this.removeAuthTokens();
    this.removeUsername();
    this.router.navigate(['connexion']);
  }

  getCurrentUser(): string | null {
    const username = this.getUsername();
    return username ? username : null;
  }

  private storeUsername(username: string): void {
    localStorage.setItem(this.CURRENT_USERNAME, username);
  }

  private getUsername(): string | null {
    return localStorage.getItem(this.CURRENT_USERNAME);
  }

  private removeUsername(): void {
    localStorage.removeItem(this.CURRENT_USERNAME);
  }

  isAuthenticated(): Observable<boolean> {
    return this.refreshToken().pipe(
      map((authTokens) => {
        if (!authTokens) {
          return false;
        }
        return true;
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  private storeAuthToken(authTokens: AuthTokens): void {
    localStorage.setItem(this.CURRENT_USER_TOKENS, JSON.stringify(authTokens));
    this.authTokenSubject.next(authTokens);
  }

  private getAuthToken(): AuthTokens {
    return JSON.parse(localStorage.getItem(this.CURRENT_USER_TOKENS));
  }

  private removeAuthTokens(): void {
    localStorage.removeItem(this.CURRENT_USER_TOKENS);
    this.authTokenSubject.next(null);
  }
}
