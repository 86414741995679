import { AddViewDialog } from './pages/states/add-view/add-view.dialog';
import { SearchGlobalService } from './../core/services/search-global.service';
import { EditColumnDialog } from './pages/states/edit-column/edit-column.dialog';
import { AddColumnsDialog } from './pages/states/add-states/add-column.dialog';
import { UserStateComponent } from './pages/user-state/user-state.component';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientsComponent } from './pages/patients/patients.component';
import { DashboardComponent } from './dashboard.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { MatStepperModule } from '@angular/material/stepper';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { AuthService } from '../auth/services/auth.service';
import { PractitionersComponent } from './pages/practitioners/practitioners.component';
import { PatientService } from './services/patient.service';
import { PractitionerService } from './services/practitioner.service';
import { PraticionersListComponent } from './components/praticioners-list/praticioners-list.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EditPractitionerComponent } from './pages/edit-practitioner/edit-practitioner.component';
import { EditPractitionerFormComponent } from './components/edit-practitioner-form/edit-practitioner-form.component';
import { InputModule } from '../components/input/input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlErrorDirectiveModule } from '../core/directives/control-error/control-error-directive.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EditPatientComponent } from './pages/edit-patient/edit-patient.component';
import { EditPatientFormComponent } from './components/edit-patient-form/edit-patient-form.component';
import { PatientsListComponent } from './components/patients-list/patients-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TelInputModule } from '../components/tel-input/tel-input.module';
import { CreditsPatientFormComponent } from './components/credits-patient-form/credits-patient-form.component';
import { MatChipsModule } from '@angular/material/chips';
import { PatientRecordComponent } from './components/patient-record/patient-record.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { PatientSwitchStatusComponent } from './components/patient-switch-status/patient-switch-status.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { PractitionerSwitchStatusComponent } from './components/practitioner-switch-status/practitioner-switch-status.component';
import { CreditsPractitionerFormComponent } from './components/credits-practitioner-form/credits-practitioner-form.component';
import { SynthesisOrdersComponent } from './pages/synthesis-orders/synthesis-orders.component';
import { SynthesisOrdersListComponent } from './components/synthesis-orders-list/synthesis-orders-list.component';
import { SynthesisService } from './services/synthesis.service';
import { ShowSynthesisOrderComponent } from './pages/show-synthesis-order/show-synthesis-order.component';
import { SynthesisOrderComponent } from './components/synthesis-order/synthesis-order.component';
import { DownloadService } from '../core/services/download.service';
import { SynthesisFileComponent } from './components/synthesis-file/synthesis-file.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ExportsComponent } from './pages/exports/exports.component';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { ExportsDatepickerComponent } from './components/exports-datepicker/exports-datepicker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ExportService } from './services/export.service';
import { ActivationKeyComponent } from './pages/activation-key/activation-key.component';
import {
  DialogContentExampleDialog,
  ProductCardComponent,
} from './pages/activation-key/product-card/product-card.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckoutComponent } from './pages/activation-key/checkout/checkout.component';
import { OrderResultComponent } from './pages/activation-key/order-result/order-result.component';
import { StepperComponent } from './pages/activation-key/stepper/stepper.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { UserDashboardComponent } from './pages/user-dashboard/user-dashboard.component';

import { MatSortModule } from '@angular/material/sort';
import { StarComponent } from './pages/user-dashboard/star/star.component';
import { UserStatusComponent } from './pages/user-dashboard/user-status/user-status.component';
import { StoreInfoComponent } from './pages/user-dashboard/store-info/store-info.component';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { KeyRequestsComponent } from './pages/key-requests/key-requests.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CompanyComponent } from './pages/company/company.component';
import { CampaignComponent } from './pages/campaign/campaign.component';
import { CampaignDetailsComponent } from './pages/campaign-details/campaign-details.component';
import { StatesComponent } from './pages/states/states.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PendingChangesGuard } from './pages/states/models/guards/pending-changes.guard';
@NgModule({
  declarations: [
    DialogContentExampleDialog,
    PatientsComponent,
    DashboardComponent,
    SidebarComponent,
    ToolbarComponent,
    PractitionersComponent,
    PraticionersListComponent,
    EditPractitionerComponent,
    EditPractitionerFormComponent,
    EditPatientComponent,
    EditPatientFormComponent,
    PatientsListComponent,
    CreditsPatientFormComponent,
    PatientRecordComponent,
    PatientSwitchStatusComponent,
    PractitionerSwitchStatusComponent,
    CreditsPractitionerFormComponent,
    SynthesisOrdersComponent,
    SynthesisOrdersListComponent,
    ShowSynthesisOrderComponent,
    SynthesisOrderComponent,
    SynthesisFileComponent,
    ExportsComponent,
    ExportsDatepickerComponent,
    ActivationKeyComponent,
    ProductCardComponent,
    CheckoutComponent,
    DialogContentExampleDialog,
    OrderResultComponent,
    StepperComponent,
    UserDashboardComponent,
    UserStateComponent,
    StarComponent,
    UserStatusComponent,
    StoreInfoComponent,
    WelcomeComponent,
    KeyRequestsComponent,
    CompanyComponent,
    CampaignComponent,
    CampaignDetailsComponent,
    StatesComponent,
    AddColumnsDialog,
    EditColumnDialog,
    AddViewDialog,
    StatesComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    FormsModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    CommonModule,
    MatInputModule,
    DashboardRoutingModule,
    MatStepperModule,
    ClipboardModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatSnackBarModule,
    InputModule,
    ReactiveFormsModule,
    MatExpansionModule,
    ControlErrorDirectiveModule,
    MatFormFieldModule,
    TelInputModule,
    MatChipsModule,
    TranslateModule,
    MatRadioModule,
    MaterialFileInputModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatDialogModule,
    MatDatepickerModule,
  ],

  providers: [
    PendingChangesGuard,
    AuthGuard,
    AuthService,
    ExportService,
    PatientService,
    PractitionerService,
    SynthesisService,
    DownloadService,
    SearchGlobalService,
  ],
})
export class DashboardModule {}
