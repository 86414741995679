import { SearchGlobalService } from './../../../core/services/search-global.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  public showToolbar: boolean = false;
  public toolbarText: string = 'Numa Health Pilot Backoffice';
  searchText;
  constructor(
    private authService: AuthService,
    private searchGlobalService: SearchGlobalService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.url.subscribe((urlSegments) => {
      const url = urlSegments.map((segment) => segment.path).join('/');
      const isPractitionersRoute = url === 'pileje/practitioners';
      const isEditPractitionerRoute = url.startsWith(
        'pileje/practitioners/edit/'
      );
      this.showToolbar = isPractitionersRoute || isEditPractitionerRoute;
      this.toolbarText = this.showToolbar
        ? 'Exalis Backoffice'
        : 'Numa Health Pilot Backoffice';
    });
    this.searchGlobalService.globalSearchStorage.subscribe((result) => {
      this.searchText = result;
    });
  }

  handleLogout(): void {
    this.authService.logout();
  }
  onSearch(input: string) {
    this.searchGlobalService.globalSearchStorage.next(input);
  }
}
