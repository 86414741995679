<div class="toolbar">
  <mat-toolbar color="primary">
    <span> {{ toolbarText }}</span>
    <span class="toolbar-spacer">
      <div class="search">
        <!-- <span class="material-icons search-icon"> search </span> -->
        <input
          class="search-text"
          type="text"
          placeholder="Recherche..."
          [(ngModel)]="searchText"
          (ngModelChange)="onSearch($event)"
        />
      </div>
    </span>
    <button (click)="handleLogout()" mat-icon-button aria-label="Déconnexion">
      <mat-icon>power_settings_new</mat-icon>
    </button>
  </mat-toolbar>
</div>
