import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExportsDatepickerComponent } from '../../components/exports-datepicker/exports-datepicker.component';
import { ExportService } from '../../services/export.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-downloadPatientsData',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss']
})
export class ExportsComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly exportService: ExportService,
  ) {}

  ngOnInit(): void {
  }

  downloadKpiData(): void {
    this.exportService.getDownloadableKpiData().subscribe(data => {
      const filename = this.generateFilename('Indicateurs de suivi Numa');
      this.triggerDownload(data.body, data.headers.get('Content-Type'), filename);
    }, error => this.handleHttpError(error));
  }

  downloadPatientsData(): void {
    this.exportService.getDownloadablePatientsData().subscribe(data => {
      const filename = this.generateFilename('Données patients');
      this.triggerDownload(data.body, data.headers.get('Content-Type'), filename);
    }, error => this.handleHttpError(error));
  }

  downloadLinearScores(): void {
    this.exportService.getDownloadableLinearScores().subscribe(data => {
      const filename = this.generateFilename('Profils santé');
      this.triggerDownload(data.body, data.headers.get('Content-Type'), filename);
    }, error => this.handleHttpError(error));
  }

  downloadActivationCodes(): void {
    this.exportService.getDownloadableActivationKeys().subscribe(data => {
      const filename = this.generateFilename('Codes d\'activation');
      this.triggerDownload(data.body, data.headers.get('Content-Type'), filename);
    }, error => this.handleHttpError(error));
  }

  downloadCompanyStatsThroughDatepicker(): void {
    const dialogReference = this.dialog.open(ExportsDatepickerComponent, {
      data: { validationButtonLabel : 'Exporter' }
    });

    dialogReference.afterClosed().subscribe(periodPicked => {
      if (periodPicked) {
        periodPicked = JSON.parse(periodPicked);
        this.exportService.getDownloadableCompanyStats(periodPicked).subscribe(data => {
          const filename = this.generateFilename('Statistiques entreprises', periodPicked);
          this.triggerDownload(data.body, data.headers.get('Content-Type'), filename);
        }, error => this.handleHttpError(error));
      }
    });
  }

  downloadUserPortalStatsThroughDatePicker(): void {
    const dialogReference = this.dialog.open(ExportsDatepickerComponent, {
      data: { validationButtonLabel : 'Exporter' }
    });

    dialogReference.afterClosed().subscribe(periodPicked => {
      if (periodPicked) {
        periodPicked = JSON.parse(periodPicked);
        this.exportService.getDownloadableUserPortalStats(periodPicked).subscribe(data => {
          const filename = this.generateFilename('Utilisations portail', periodPicked);
          this.triggerDownload(data.body, data.headers.get('Content-Type'), filename);
        }, error => this.handleHttpError(error));
      }
    });
  }

  // FIXME
  //  Title should be obtainable from response header 'Content-Disposition' but is currently always
  //  null... Adding allowed headers in backend didn't solve the issue
  private generateFilename(context: string, period = null): string {
    let formattedDate = '';
    if (!period) {
      const now = new Date();
      formattedDate = `${now.toLocaleDateString('fr-FR')}`;
    } else {
      formattedDate = `${new Date(period.start).toLocaleDateString('fr-FR')}-${new Date(period.end).toLocaleDateString('fr-FR')}`;
    }

    return `[Export Numa] ${context} - ${formattedDate}.csv`;
  }

  private triggerDownload(data, type, filename): void {
    const blob = new Blob([data], { type: type });
    const url = URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.download = filename;
    anchor.href = url;
    anchor.click();
  }

  private handleHttpError(error): void {
    let errorMessage = JSON.parse(error.error);
    errorMessage = errorMessage.message ? errorMessage.message : error.statusText;

    this.snackBar.open(
      `Une erreur est survenue lors de la génération de l'export : ${errorMessage}`,
      '',
      { verticalPosition: 'top', horizontalPosition: 'right', duration: 5000 },
    );
  }
}
