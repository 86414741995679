import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  private readonly PILEJE_ADMIN_USERNAME = 'pilejeAdmin';
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      tap((isAuthenticated) => {
        if (
          !isAuthenticated ||
          localStorage.getItem('current_username') ===
            this.PILEJE_ADMIN_USERNAME
        ) {
          this.authService.logout();
        }
      })
    );
  }
}
