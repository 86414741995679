import { Component, OnInit } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import {
  Patient,
  PatientPaginationDto,
} from '../../../core/models/patient.model';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SearchGlobalService } from 'src/app/core/services/search-global.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnInit {
  public patientPagination: PatientPaginationDto;
  public patients: MatTableDataSource<Patient>;
  constructor(
    private patientService: PatientService,
    private router: Router,
    private globalSearchService: SearchGlobalService
  ) {}

  ngOnInit() {
    // this.fetchPage();
    let page;
    this.patientService.getAll(page, 9999).subscribe((patientPagination) => {
      this.patientPagination = patientPagination;
      this.patients = new MatTableDataSource<Patient>(patientPagination.items);
      this.globalSearchService.globalSearchStorage.subscribe((result) => {
        if (result) {
          this.applyFilter(result);
        } else {
          this.patientService.patientList.next(patientPagination.items);
          this.patients = new MatTableDataSource<Patient>(
            patientPagination.items
          );
        }
      });
    });
  }

  async fetchPage(page?: number) {
    this.patientService.getAll(page, 9999).subscribe((patientPagination) => {
      this.patientPagination = patientPagination;
      this.patientService.patientList.next(patientPagination.items);
      this.patients = new MatTableDataSource<Patient>(patientPagination.items);
    });
  }

  handleRowClick(patient: Patient): void {
    this.router.navigate(['dashboard', 'patients', 'edit', patient.id]);
  }

  handlePage(pageEvent: PageEvent): void {
    this.fetchPage(pageEvent.pageIndex);
  }

  applyFilter(event: Event | string) {
    if (typeof event === 'string') {
      if (this.patients) {
        this.patients.filter = event.trim().toLowerCase();
        this.fetchPage(0)
      }
    }
  }
}
